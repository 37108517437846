@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.linkText {
  color: red;
}


/* Estilo del Input del input de configuracion del editor de image */

.inputs input[type="number"] {
  border: 0;
  border-bottom: 1px dotted red;
  color: red;
  margin-left: 10px;
  text-align: center;
  width: 35px;
}

.inputs input[type="number"]:focus {
  outline: none;
  border-bottom: 2px solid red;
}

.inputs input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.imageCategoriaEdit {
  background-size: cover;
}

/* box edit design  */


.boxEditDesign {
  position: relative;
}

.boxEditDesign::before {
  content: '';
  position: absolute;
  background-color: red;
  width: 100%;
  height: 100%;
}

.loader {
  position: relative;
  width: 50px;
  height: 50px;
  border: 4px solid transparent;
  border-radius: 50%;
  border-top: 4px solid var(--heading-color);
  animation: rotate 1.5s linear infinite;
}

.loader::before,
.loader::after {
  position: absolute;
  content: "";
  border-radius: 50%;
  box-sizing: border-box;
  border: 4px solid transparent;
}

.loader::after {
  inset: 0;
  border-bottom: 4px solid var(--main-color);
  animation: rotate 0.5s linear infinite reverse;
}

.loader::before {
  inset: 4px;
  border-left: 4px solid var(--main-color);
  animation: rotate 1.5s linear infinite;
}

/* validacion de los input de orden */

input:invalid+span::after {
  content: "✖";
  padding-left: 5px;
  color: red;
}

input:valid+span::after {
  content: "✓";
  padding-left: 5px;
}

/* Estilos scroll en el body y en clases*/

body::-webkit-scrollbar,
.styleScroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

body::-webkit-scrollbar:vertical,
.styleScroll::-webkit-scrollbar:vertical {
  width: 10px;
}

body::-webkit-scrollbar-button:increment,
.styleScroll::-webkit-scrollbar-button:increment,
.styleScroll::-webkit-scrollbar-button,
body::-webkit-scrollbar-button {
  display: none;
}

body::-webkit-scrollbar:horizontal,
.styleScroll::-webkit-scrollbar:horizontal {
  height: 10px;
}

body::-webkit-scrollbar-thumb,
.styleScroll::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

body::-webkit-scrollbar-track,
.styleScroll::-webkit-scrollbar-track {
  border-radius: 10px;
}